export const SEAT_MAP = new Map([
  [
    "MSV4RS",
    {
      riderSeatMap: new Map([
        ["CV000029", ["CV000030"]],
        ["CV000410", ["CV000389", "CV000750", "CV000152"]],
        ["CV000412", ["CV000389", "CV000750", "CV000152"]],
        ["CV002184", ["CV000389", "CV000750", "CV000152"]],
        ["CV000408", ["CV000389", "CV000750", "CV000152"]],
      ]),
      passengerSeatMap: new Map([
        ["CV000030", ["CV000029"]],
        ["CV000389", ["CV000410", "CV000412", "CV002184", "CV000408"]],
        ["CV000750", ["CV000410", "CV000412", "CV002184", "CV000408"]],
        ["CV000152", ["CV000410", "CV000412", "CV002184", "CV000408"]],
      ]),
    },
  ],
]);

export const getCompatibleSeats = (version = "", key = "") => {
  let result = [];
  let isRider = false;
  const toUpperVersion = version.toUpperCase();
  const seatMap = SEAT_MAP.get(toUpperVersion);
  if (seatMap?.riderSeatMap?.size > 0) {
    const riderList = seatMap.riderSeatMap.get(key);
    if (riderList?.length > 0) {
      result = riderList;
      isRider = true;
    }
  }
  if (seatMap?.passengerSeatMap?.size > 0 && !isRider) {
    const passengerList = seatMap.passengerSeatMap.get(key);
    if (passengerList?.length > 0) {
      result = passengerList;
    }
  }
  return result;
};
